import React from "react"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/SEO"
import Image from "gatsby-image"
import Layout from "../components/Layout"

const About = ({data: {about: {nodes}}}) => {
  const { info, title, stack, image} = nodes[0];
  return (
    <Layout>
      <Seo title="About" description="About Jacinto Mendes. Web Developer."></Seo>
      <section className="about-page">
          <div className="section-center about-center">
            <Image fluid={image.childImageSharp.fluid} className="about-img"/>
            <article className="about-text">
              <Title title={title}></Title>
              <p>{info}</p>
              <div className="about-stack">
                {
                  stack.map((stackItem) => {
                    return <span key={stackItem.id}>{stackItem.title}</span>
                  })
                }
              </div>
            </article>
          </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    about:allStrapiAbout {
      nodes {
        stack {
          id
          title
        }
        title
        info
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default About
